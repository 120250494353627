<template >
  <div class="orders-wrapper" @change=filterSearch>
    <Loading v-if="isLoading"></Loading>
    <PDataTable v-else
    :columnContentTypes='["text","numeric","numeric","numeric"]'
    :headings='[{"content":"Fulfillment","value":"fulfillmentMethod","type":"text","sortable":false,"width":"10%"},{"content":"Email","value":"email","type":"text","sortable":false,"width":"10%"},{"content":"Date","value":"date","type":"text","sortable":true,"width":"10%"},{"content":"Order Id","value":"order_id","type":"text","sortable":false,"width":"10%"},{"content":"Order #","value":"order_number","type":"text","sortable":false,"width":"10%"},{"content":"NFT","value":"nft","type":"text","sortable":false,"width":"10%"},{"content":"Product Id","value":"product_id","type":"text","sortable":false,"width":"10%"},{"content":"Minted","value":"minted","type":"text","sortable":false,"width":"10%"},{"content":"Status","value":"fulfillment_status","type":"text","sortable":false,"width":"10%"}, {"content":"Tracking","value":"transaction","type":"text","sortable":false,"width":"10%"}]'
    :rows=displayOrders
    :sort='{"value":"date","direction":"ascending"}'
    hasFilter
    searchPlaceholder="Search Orders"
    :hasPagination="false"
    :pagination='{"hasPrevious":true,"hasNext":true}'
    type=""
    :input-filter-changed=filterSearch
    :sort-changed=sortChange
    @change=filterSearch
    @click="filterSearch"
    v-model="search"
  >
    <template slot="filter">
      <PPopover
        id="popover_1"
        :active="filter"
        preferredAlignment="right"
        fullWidth=""
      >
        <PButton @click="handleFilter" slot="activator" disclosure="down">Filter</PButton>
        <POptionList
          v-if="filter"
          slot="content"
          allowMultiple=""
          :options='[{"label":"Minted","value":"minted"},{"label":"Fulfilled","value":"fulfilled"}]'
          :selected="[]"
        />
      </PPopover>
    </template>
  </PDataTable>
  </div>
</template>

<script>
import firebase from 'firebase'
import Loading from '../components/Loading.vue';
import axios from 'axios';

export default {
  name: 'Orders',
  props: ['site', 'settings'],
  components: {
    Loading
  },
  data(){
    return {
     filter: false,
     isLoading: true,
     isReLoading: false,
     title: "Orders",
     orders: [],
     displayOrders: [],
     errors: '',
     search: '',
     expanded: [],
     sortDirection:'asc',
     singleExpand: true,
     apiUrl: process.env.VUE_APP_BACKEND,
     headers: [
      {
        text: 'Order Email',
        align: 'start',
        value: 'orderEmail',
      },
      { text: 'Order Date', value: 'orderDate' },
      { text: 'Shopify Order Id', value: 'orderId' },
      { text: 'NFT Name', value: 'nftName' },
      { text: 'Shopify Product Id', value: 'productId' },
      { text: 'Minted Status', value: 'mintedStatus' },
      { text: 'Fulfillment Id', value: 'fulfillmentId' },
      { text: 'Fulfillment Status', value: 'fulfillmentStatus' }
    ]
    }
  },
  methods: {
    async fulfill(e){
      this.isLoading = true;
      let fId = e.target.getAttribute('fulfill')
      let orderRef = await firebase.firestore().collection('orders').doc(fId).get()
      if(orderRef.exists){
        let order = orderRef.data();

        await axios({
          method:'post',
          url: `${this.apiUrl}orders/fulfill`,
          data: order
        })

      }
      await this.getOrders();
      this.isLoading = false;
    },
    handleAction(value){
      console.log("ACTION", value)
    },
    filterSearch(){
      if(document.getElementById("Polaris-Input-Filter") === null ){
        console.log(`search is empty`)
      }else{
        this.search = document.getElementById("Polaris-Input-Filter").value.toLowerCase();
        if(this.search && this.search !== ''){
          this.filterOrders();
        }
        else{
          this.displayOrders = this.orders;
        }
      }
    },
    filterOrders(){
      let displayOrders = [];
      for(let order of this.orders){
        for(let orderx of order){
          orderx = orderx.toString();
          orderx = orderx.toLowerCase();
          if(orderx.includes(this.search)){
            displayOrders.push(order)
          }else{
            //displayOrders = []
          }
        }
      }

      this.displayOrders = displayOrders;
      // if(displayOrders.length){
      //   this.displayOrders = displayOrders;
      // }
    },
    sortChange(){
      this.displayOrders = this.displayOrders.reverse()
    },
    handleFilter(){
      if(this.filter){
        this.filter = false
      }
      else{
        this.filter = true;
      }
    },
    async getOrders(){
      this.isReLoading = true;
      this.orders = [];
      this.displayOrders = [];
      let ordersRef = await firebase.firestore().collection('orders').where('data.site', '==', this.site).get()
      if(ordersRef.docs && ordersRef.docs.length > 0){
        for(let orderx of ordersRef.docs){
          let { order, data, mintedStatus, fulfillmentStatus, minted, fulfillmentId, fulfillmentMethod } = orderx.data();
          let { id, created_at, order_number } = order;
          let { nftId, orderEmail, orderId } = data;
          let nftRef = await firebase.firestore().collection('nfts').doc(nftId).get();
          let { nft, shopify_id } = nftRef.data();
          let { name } = nft;
   //       let tokenId;
          let transactionHash;
     //     let contractAddress;
          let orderDate = new Date(created_at).toDateString();
          let status = 'fail';
          let progress = 'incomplete';
          let Fstatus = 'fail';
          let Fprogress = 'incomplete';
          let fulFillmentObj = fulfillmentId;

          if(mintedStatus === 'cancelled'){
            status = 'cancelled';
            progress = 'complete'
          }else if(mintedStatus !== 'not minted' ){
      //      tokenId = minted[0].tokenIds[0];
              transactionHash = minted[0].transactionHash;
       //     contractAddress = minted[0].metadata.contract.address
            if(mintedStatus !== 'FAILED'){
                  status = 'success'
                  progress = 'complete'
            }else{
              status = 'critical';
              progress = 'complete'
              transactionHash = minted[0].transactionHash;
            }
          }
          if(mintedStatus === 'SUCCEEDED'){
            mintedStatus = 'success'
          }


          if((fulfillmentId && fulfillmentId !== '' && fulfillmentId !== 'error' && fulfillmentStatus !== 'not fullfilled') || (fulfillmentStatus.includes('lready fulfilled'))){
            Fstatus = 'success'
            Fprogress = 'complete'
            fulfillmentMethod = 'Fulfilled';
            fulfillmentStatus = "success";
            fulFillmentObj = {
              "content": fulfillmentId,
              "url": `https://${this.site}/admin/orders/${id}/fulfillments/${fulfillmentId}.json`
            }
          }
          else if(fulfillmentStatus == 'Line items are already fulfilled' || fulfillmentId == 'error' || fulfillmentStatus.includes('is already fulfilled')){
            fulfillmentMethod = 'Fulfilled';
          }
          else if((mintedStatus === 'pending' || mintedStatus === 'success' || mintedStatus === 'SUCCEEDED') && (fulfillmentStatus === 'pending' || fulfillmentStatus === 'not fullfilled')){
            fulfillmentMethod = 'Fulfillment Pending';
            fulfillmentStatus = 'pending'
          }else if(mintedStatus === 'cancelled'){
            fulfillmentStatus = 'cancelled'
          }else{
            fulfillmentStatus = 'pending'
          }

          //let trackingUrl = `https://mumbai.polygonscan.com/tx/${transactionHash}/internal-transactions`;
          let trackingUrl = `https://polygonscan.com/tx/${transactionHash}/internal-transactions`;
          let trackingObj;
          if(transactionHash){
            trackingObj = {
                "content": "Tracking URL",
                "url": trackingUrl
            }
          }else{
            trackingObj = "None"
          }

          let fulfillmentMethodObj = fulfillmentMethod;

          if(fulfillmentStatus === 'cancelled'){
            fulfillmentMethod = 'Cancelled';
            fulfillmentMethodObj = "Cancelled"
          }else if(!fulfillmentMethod || fulfillmentMethod !== 'Auto' && fulfillmentMethod !== 'Fulfilled' && mintedStatus !== 'FAILED' && fulfillmentMethod !== 'Fulfillment Pending'){
            fulfillmentMethod = 'Manual Fulfill';
            fulfillmentMethodObj =
            {"content": fulfillmentMethod, "url": orderId}
          }

          console.log(fulFillmentObj)

          let orderArr = [
            fulfillmentMethodObj, orderEmail, orderDate, {"content": id, "url": `https://${this.site}/admin/orders/${id}`}, order_number, name, {"content": shopify_id, "url": `https://${this.site}/admin/products/${shopify_id}`},
            {"content": mintedStatus, "status":status,"progress":progress}, {"content": fulfillmentStatus, "status":Fstatus,"progress":Fprogress},
            trackingObj
          ]
          this.orders.push(orderArr)
          this.displayOrders.push(orderArr)
        }
      }
      else{
        this.errors = 'No Orders found on your store.'
      }
      this.displayOrders = this.displayOrders.reverse();
      this.isReLoading = false;
      let self = this;
        setTimeout(function(){
          let orderss = Array.from(document.querySelectorAll('.Polaris-DataTable__Cell--firstColumn a'));
          let buttons = Array.from(document.querySelectorAll('.Polaris-DataTable__Table button'));
          for(let button of buttons){
            button.addEventListener('click', self.sortChange);
          }
          for(let orders of orderss){
              let id = orders.getAttribute('href')
              orders.setAttribute("fulfill", id)
              orders.addEventListener('click', self.fulfill);
              orders.setAttribute("href", "#")
              orders.removeAttribute("target")
          }
        }, 500);

        setTimeout(() => {
          let as = document.getElementsByClassName("Polaris-Link");
          let aa = [...as]
          aa.forEach(a => {
            let hrefx = a.getAttribute('fulfill')

            if(!hrefx || hrefx == 'null' || hrefx == null){

              let openx = a.getAttribute('href')
              if(openx.includes('.myshopify.com')){
                a.addEventListener('click', function (event) {
                  event.stopPropagation();
                  window.top.location.href = openx
                });
                a.removeAttribute('href')
              }else{
                a.target = "_blank"
              }

            }
          })
        }, 500);
    },
    async checkBilling(){
        let siteRef = await firebase.firestore().collection('sites').doc(this.site).get()
        let { billing } = siteRef.data()
        //check billing...
        if(billing.recurring_application_charge.test !== undefined){
          if(billing.recurring_application_charge.test === true && this.site !== 'venly-merchant-demo.myshopify.com'){
            if( this.$route.path !== '/settings'){
              this.$router.push({ path: `/settings`, query: { shop: this.site } })
              console.log('go redirect...')
            }
          }
        }
      }
  },
  mounted: async function () {
    await this.checkBilling()
    if(!this.settings){
      this.$router.push({ path: `/` })
    }
    await this.getOrders();
    setTimeout(() => {
      let as = document.getElementsByClassName("Polaris-Link");
      let aa = [...as]
      aa.forEach(a => {
        let hrefx = a.getAttribute('fulfill')

        if(!hrefx || hrefx == 'null' || hrefx == null){

          let openx = a.getAttribute('href')
          if(openx.includes('.myshopify.com')){
            a.addEventListener('click', function (event) {
              event.stopPropagation();
              //window.open(openx, '_blank').focus();
              //window.open(openx, '_self').focus();
              window.top.location.href = openx
              //window.parent.location.href = openx
            });
            a.removeAttribute('href')
          }else{
            a.target = "_blank"
          }

        }
      })
    }, 800);

    this.isLoading = false;
    window.setInterval(() => {
   //   this.getOrders();
    }, 30000)
  }
}
</script>

<style scoped>
  .error {
    color: red;
    margin-top: 10px;
  }
  .orders-table {
    padding: 10px;
  }
  .orders-content {
    margin-top: 20px;
  }
  .expanded-item {
    text-align: left !important;
    padding: 10px !important;
  }
  .Polaris-Filters-ConnectedFilterControl__RightContainer {
    display: none !important;
  }
</style>
