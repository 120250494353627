
<template>
  <PFormLayout class="login-form">
   <PSubheading class="settings-header" element="h2">Login To Your Shopify Store To Use This Application</PSubheading>
       <PTextField
       v-if="error"
      label="Store URL"
      type="text"
      v-model="storeUrl"
      placeholder="Store URL Ex: arkane-demo-store.myshopify.com"
      error="Please enter valid store url"
    />
    <PTextField
    v-else
      label="Store URL"
      type="text"
      v-model="storeUrl"
      placeholder="Store URL Ex: arkane-demo-store.myshopify.com"
    />
                <PButton
      @click='login' 
      class="submit-form"
      >Login</PButton>
  </PFormLayout>
</template>

<script>
export default {
   name: 'Login',
     props: ['site', 'installed'],
     data(){
      return {
        storeUrl: '',
        error: false
      }
     },
    methods: {
      login() {
         if(this.storeUrl && this.storeUrl !== ''){
            this.error = false;
            location.assign(`https://${this.storeUrl}/admin`)
         }
         else{
            this.error = true;
         }
      }
    },
    mounted: function () {
      if(this.site && this.installed){
        location.assign(`https://${this.site}/admin/apps/arkane-networks`)
      }
    }
};
</script>

<style scoped>
.login-form{
   margin: 0 auto;
   max-width: 70%;
}
.login-form .flex{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.login-form .v-card {
  width: 50%;
}
@media screen and (max-width: 768px){
  .login-form {
    max-width: 95%;
  }
}
</style>
