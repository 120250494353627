<template>
  <div class="install-wrapper">
    <div class="install">
      <Loading v-if="isLoading"></Loading>
      <h1 v-if="!isLoading && !redirect">{{ installTxt }}</h1>
      <p v-if="!isLoading && !redirect"><router-link class="text-link text-center" to="/home">{{ homeTxt }}</router-link></p>
     <PSubheading v-if="!isLoading" class="install-header" element="h1">Select Your Plan</PSubheading>
  
  
  <PStack  vertical spacing="tight" distribution="fill" alignment="fill" v-if="!isLoading">
    <PStackItem>
        <PRadioButton
        v-if="plan === 'Basic'"
          id="Basic"
          label="Basic"
          helpText="$49 Per Month and 7% NFT Transaction Fee"
          name="Basic"
          checked
          v-model="plan"
        />
        <PRadioButton
              v-else
          id="Basic"
          label="Basic"
          helpText="$49 Per Month and 7% NFT Transaction Fee"
          name="Basic"
          v-model="plan"
          :checked=false
        @change="changePlan('Basic')"
        />
      <PList>
        <PListItem>Monthly Price: $49</PListItem>
        <PListItem>Transaction fee per sale*: 7%</PListItem>
        <PListItem>Minimum fee per sale*: $1.00</PListItem>
        <PListItem>File Size per NFT: 5MB</PListItem>
        <PListItem>NFT Templates: 5</PListItem>
        <PListItem>Account Support: Yes</PListItem>
      </PList>
    </PStackItem>
        <PStackItem>
        <PRadioButton
                v-if="plan === 'Pro'"
        id="Pro"
        label="Pro"
        helpText="$399 Per Month and 4% NFT Transaction Fee"
        name="Pro"
        checked
      />
      <PRadioButton
      v-else
        id="Pro"
        label="Pro"
        helpText="$399 Per Month and 4% NFT Transaction Fee"
        name="Pro"
        v-model="plan"
        :checked=false
      @change="changePlan('Pro')"
      />
              <PList>
    <PListItem>Monthly Price: $399</PListItem>
    <PListItem>Transaction fee per sale*: 4%</PListItem>
    <PListItem>Minimum fee per sale*: $0.50</PListItem>
    <PListItem>File Size per NFT: 25MB</PListItem>
    <PListItem>NFT Templates: 25</PListItem>
    <PListItem>Account Support: Yes</PListItem>
  </PList>
    </PStackItem>
            <PStackItem>
                    <PRadioButton
                    v-if="plan === 'Enterprise'"
        id="Enterprise"
        label="Enterprise"
        helpText="$999 Per Month and 2.25% NFT Transaction Fee"
        name="Enterprise"
        checked
      />
      <PRadioButton
      v-else
        id="Enterprise"
        label="Enterprise"
        helpText="$999 Per Month and 2.25% NFT Transaction Fee"
        name="Enterprise"
      :checked=false
      @change="changePlan('Enterprise')"
      />
              <PList>
    <PListItem>Monthly Price: $999</PListItem>
    <PListItem>Transaction fee per sale*: 2.25%</PListItem>
    <PListItem>Minimum fee per sale*: $0.25</PListItem>
    <PListItem>File Size per NFT: 100MB</PListItem>
    <PListItem>NFT Templates: Unlimited</PListItem>
    <PListItem>Account Support: Yes</PListItem>
  </PList>
    </PStackItem>
  </PStack>
     <p v-if="!isLoading">Note* The transaction fee is a minimum fee or the % of the sale price</p>
      <PButton v-if="!isLoading"
      @click='go' 
      class="install-submit"
      >Install</PButton>
    </div>
  </div>
</template>

<script>
import Loading from '../components/Loading.vue';
import axios from 'axios';
const nonce = require('nonce')();

export default {
  name: 'Install',
  props: ['site', 'installed'],
  components: {
    Loading
  },
  data(){
    return {
     isLoading: true,
     redirect: false,
     installTxt: "Installed!",
     homeTxt: 'Go Home',
     apiUrl: process.env.VUE_APP_BACKEND,
     shopifyKey: process.env.VUE_APP_SHOPIFY_API_KEY,
     scopes: process.env.VUE_APP_SCOPES,
     redirectUri: `${process.env.VUE_APP_FRONTEND}/install`,
     accessMode: "offline",
     plan: "Basic"
    }
  },
  methods: {
    changePlan(plan){
      this.plan = plan;
    },
    async go(){
      const shop      = this.$route.query.shop;
      const code      = this.$route.query.code;
      //console.log(shop, code, this.plan)
      if(code && shop && this.plan){
        this.isLoading = true;
        await axios({
        method:'post',
        url: `${this.apiUrl}install/${shop}/${code}/${this.plan}`
        }).then(function(response){
          window.top.location.href = response.data
          //window.open(response.data, '_self').focus();
        }).catch(function() {
        });
      }
    }
  },
  mounted: async function () {
    const shop      = this.$route.query.shop;
    const code      = this.$route.query.code;
    const charge_id = this.$route.query.charge_id;
    const install     = this.$route.query.install;
    const nonce_str = nonce().toString();
    const url       = `https://${shop}/admin/oauth/authorize?client_id=${this.shopifyKey}&scope=${this.scopes}&redirect_uri=${this.redirectUri}&state=${nonce_str}&grant_options[]=${this.accessMode}`;
    if(shop && !code && !charge_id){
      //location.assign(url);
      window.top.location.href = url
    }
    else if(code && install != 'true'){
      //console.log(code, install)
      this.redirectUri = `https://${shop}/admin/apps/arkane-networks?code=${code}&install=true&shop=${shop}`;
      window.top.location.href = this.redirectUri
      //location.assign(this.redirectUri);
    }
    else if(charge_id){
       this.isLoading = false;
      this.redirect  = true;
    }
    else if(!shop && !code){
      this.redirect  = true;
      this.isLoading = false;
    }
    else{
        this.redirect  = true;
        this.isLoading = false;
    }
  }
}
</script>

<style scoped>
.install-submit {
  margin-top: 20px;
}
.Polaris-Stack--vertical {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
      justify-content: center;
  margin: 0 auto;
    margin-bottom: 20px;
}
.Polaris-Stack__Item {
  border: 2px solid #000;
  border-radius: 5px;
  padding: 20px;
  margin-right: 20px;
      display: flex;
    align-items: center;
    justify-content: center;
}
.Polaris-List {
  margin-left: 20px;
}
@media screen and (max-width: 768px){
  .Polaris-Stack--vertical {
    flex-direction: column;
  }
}
</style>

