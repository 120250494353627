<template>
  <div class="products-wrapper">
    <v-snackbar
      absolute
      right
      top
      :value="alert"
      :timeout="timeout"
      elevation="24"
    >NFT Created!</v-snackbar>
    <Loading v-if="isLoading"></Loading>
    <div class="products" v-else>
      <h1>{{ title }}</h1>
      <div class="selected-product" v-if="productSelected">
        <v-form class="product-form">
          <h3 class="selected-product-title">Selected Product</h3>
          <v-text-field
            label="Name"
            v-model='name'
          ></v-text-field>
          <v-text-field
            label="Description"
            v-model='description'
          ></v-text-field>
          <v-text-field
            label="Image"
            v-model='image'
          ></v-text-field>
          <v-text-field
            label="External Url"
            v-model='externalUrl'
          ></v-text-field>
          <v-radio-group v-model="fungible">
            <v-radio
              :key="false"
              label=" Non-Fungible"
              :value="false"
            ></v-radio>
              <v-radio
              :key="true"
              label=" Fungible"
              :value="true"
            ></v-radio>
          </v-radio-group>
          </v-form>
      </div>
      <v-btn @click='submit' class="submit-form"
      elevation="2"
      :class="{ disabled: productSelected === false }"
      >{{ buttonTxt }}</v-btn>
      <p class="error" v-if="errors.length > 0">{{ errors }}</p>
      <v-form class="search-form" v-if="products.length > 0">
        <v-text-field
          @input="handleSearch"
          label="Search By Title"
          v-model='search'
        ></v-text-field>
      </v-form>
      <div class="product-container" v-if="products.length > 0">
        <Loading v-if="isLoadingProducts"></Loading>
        <v-card v-else @click="selectProduct(product.id)" class="product" v-for="product in displayProducts" :key="product.id"
        :class="{ active: product_id === product.id }"
          elevation="3"
          outlined>
          <h3>{{ product.title }}</h3>
          <img :src=product.image.src />
          <p>ID: {{ product.id }}</p>
        </v-card>
        <Loading v-if="loadingMoreProducts"></Loading>
      </div>
    </div>
      <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import firebase from 'firebase'
import Loading from '../components/Loading.vue';
import axios from 'axios';

export default {
  name: 'Products',
  props: ['site', 'settings'],
  components: {
    Loading
  },
  data(){
    return {
     isLoading: true,
     isLoadingProducts: false,
     title: "Products",
     shopify_public_token: '',
     product_id: '',
     selectedProduct: '',
     id: '',
     name: '',
     description: '',
     image: '',
     externalUrl: '',
     errors: '',
     isOk: false,
     alert: false,
     timeout: 2500,
     apiUrl: process.env.VUE_APP_BACKEND,
     products: [],
     displayProducts: [],
     productSelected: false,
     buttonTxt: 'Select Product',
     search: '',
     loadingMoreProducts: false,
     fungible: false,
     overlay: false
    }
  },
  methods: {
    handleSearch(){
      this.isLoadingProducts = true;
      if(this.search.length > 0){
        this.displayProducts = this.products.filter(product => product.title.toLowerCase().includes(this.search.toLowerCase()) || product.title.toLowerCase() === this.search.toLowerCase());
        if(this.displayProducts.length < 1){
        this.displayProducts = this.products
        }
      }
      else{
        this.displayProducts = this.products
      }
      this.isLoadingProducts = false;
    },
    selectProduct(product){
      this.product_id = product;
      this.productSelected = true;
      this.selectedProduct = this.products.find(product => product.id === this.product_id);
      this.id = this.selectedProduct.id;
      this.name = this.selectedProduct.title;
      this.description = this.selectedProduct.body_html;
      this.image = this.selectedProduct.image.src;
      this.buttonTxt = 'Create NFT';
    },
    checkSubmit(){
      this.errors = '';
      if(this.shopify_public_token.length !== '' && this.products.length > 0 && this.productSelected &&
      this.id !== '' && this.name !== '' &&
      this.description !== '' && this.image !== '' && this.externalUrl !== ''){
        this.isOk = true;
      }
      else{
        this.errors = 'Invalid data. Please try again.';
      }
    },
    async submit(){
      this.checkSubmit();
      if(this.isOk){
        this.overlay = true;
        let product = {
          site: this.site,
          id: this.product_id,
          created: false,
          product: {
            id: this.id,
            name: this.name,
            description: this.description,
            image: this.image,
            externalUrl: this.externalUrl,
            fungible: this.fungible
          }
        }
        let productResults = await axios({
        method:'post',
        url: `${this.apiUrl}nft/create`,
        data: product
        }).then(function(response){
          return response.data;
        }).catch(function() {
        });
        if(productResults){
          product.created = true;
          product.nft = productResults.nft;
          product.destination = productResults.destination;
          product.tokenId = productResults.tokenId
        }
        let docId = `${this.product_id}`;
        await firebase.firestore().collection('products').doc(docId).set(product)
        this.overlay = false;
        this.alert = true;
        this.productSelected = false;
        this.selectedProduct = '';
        this.product_id = '';
        this.buttonTxt = 'Select Product';
      }
    },
    async getProducts(){
       this.products = await axios({
        method:'get',
        url: `${this.apiUrl}products/${this.site}/${this.shopify_public_token}`
        }).then(function(response){
         return response.data;
        }).catch(function(error) {
          console.log(error)
          this.errors = 'Error getting products. Please try again.'
        });
        this.displayProducts = this.products;
        if(this.products.length < 1){
          this.errors = 'Error getting products. Please try again.'
        }
        if(this.products.length > 249){
          this.loadingMoreProducts = true;
          this.getAllProducts();
        }
    },
    async getAllProducts(){
      this.products = await axios({
      method:'get',
      url: `${this.apiUrl}products/all/${this.site}/${this.shopify_public_token}`
      }).then(function(response){
        return response.data;
      }).catch(function(error) {
        console.log(error)
        this.errors = 'Error getting products. Please try again.'
      });
      this.displayProducts = this.products;
    },
    async checkBilling(){
        let siteRef = await firebase.firestore().collection('sites').doc(this.site).get()
        let { billing } = siteRef.data()
        //check billing...
        if(billing.recurring_application_charge.test !== undefined){
          if(billing.recurring_application_charge.test === true && this.site !== 'venly-merchant-demo.myshopify.com'){
            if( this.$route.path !== '/settings'){
              this.$router.push({ path: `/settings`, query: { shop: this.site } })
              console.log('go redirect...')
            }
          }
        }
      }
  },
  mounted: async function () {
    await this.checkBilling()
    let siteRef = await firebase.firestore().collection('sites').doc(this.site).get()
    if(siteRef.exists){
      let { shopify_public_token } = siteRef.data();
      if(shopify_public_token){
        this.shopify_public_token = shopify_public_token;
      }
    }
    else{
      this.$router.push({ path: `/` })
    }
    if(!this.settings){
      this.$router.push({ path: `/` })
    }
    await this.getProducts();
    this.isLoading = false
  }
}
</script>

<style scoped>
  .error {
    color: red;
    margin-top: 10px;
  }
  .selected-product-title {
    text-align: left;
  }
  .product {
    cursor: pointer;
    padding: 5px;
    margin-bottom: 10px;
  }
  .product.active {
    background: #f5f5f5;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
  .product-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
  .product img {
    max-width: 200px;
    margin: 10px 0;
  }
  .submit-form.v-btn.disabled {
    pointer-events: none;
    cursor: default;
  }
  .submit-form {
    margin-top: 10px;
  }
  .product-form .v-text-field {
    max-width: 50%;
    align-self: center;
  }
  @media screen and (max-width: 768px){
    .product-form .v-text-field {
      max-width: 99%;
    }
    .product-container {
      grid-template-columns: 1fr 1fr;
    }
    .product img {
      max-width: 150px;
    }
  }
</style>
